<template>
  <v-main>
    <div class="error text-center white--text title" v-if="serverError">{{ serverError }}</div>
    <v-container class="fill-height" fluid>
      <v-row justify="center" align="center">
        <v-col cols="3">
          <v-card>
            <v-card-title class="justify-center white--text primary">
              Восстановление пароля
            </v-card-title>
            <v-form v-model="dataValid">
              <v-card-text class="pb-0">
                <v-row class="px-2">
                  <v-text-field
                    v-model="email"
                    label="Пожалуйста, укажите email"
                    :rules="[rules.required, rules.email]"
                  ></v-text-field>
                </v-row>
              </v-card-text>
            </v-form>
            <v-card-actions>
              <v-btn color="info" to="/login">Назад</v-btn>
                <v-spacer></v-spacer>
              <v-btn color="success" @click="reset" :loading="loading" :disabled="loading||!dataValid">Далее</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import rules from '@/libs/validate-rules.js'
export default {
  data: () => ({
    email: '',
    rules: rules,
    dataValid: false,
    loading: false
  }),
  methods: {
    reset() {
      this.loading = true
      this.$axios.post('user/account/password-reset-request', { email: this.email }).then(res => {
        this.$router.push('/login')
      }).catch(err => {
        if(err.response.data.error) {
          this.serverError = err.response.data.error
        } else {
          this.serverError = 'Не удалось соедениться с сервером'
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}

</script>
